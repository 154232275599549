import React, { isValidElement, cloneElement } from 'react'
import { helpers } from '@contentful/rich-text-types'
import {
  defaultNodeRenderers,
  defaultMarkRenderers,
  defaultTextRenderers
} from './renderers'

function appendKeyToValidElement(element, key) {
  if (isValidElement(element) && element.key === null) {
    return cloneElement(element, { key })
  }
  return element
}
function nodeListToReactComponents(nodes, defaultRenderers, options) {
  return nodes?.map((node, index) => {
    return appendKeyToValidElement(
      nodeToReactComponent(node, defaultRenderers, options),
      index
    )
  })
}

function nodeToReactComponent(node, defaultRenderers, options = {}) {
  const { renderNode, renderMark, renderText } = defaultRenderers
  if (node) {
    if (helpers.isText(node)) {
      const textRenderer = options.text && options.text.renderer
      const textOptions = options.text && options.text.options
      return node.marks.reduce(
        (value, mark) => {
          if (!renderMark[mark.type]) {
            return value
          }
          const markOptions = options[mark.type] || {}
          if (markOptions.renderer) {
            const renderer = markOptions.renderer
            const options = markOptions.options || {}
            return renderer(value, null, options)
          }

          return renderMark[mark.type](value, null, markOptions.options)
        },
        textRenderer
          ? textRenderer(node.value, null, textOptions)
          : renderText.text(node.value, null, options)
      )
    }
    const children = nodeListToReactComponents(
      node.content,
      defaultRenderers,
      options
    )
    if (!node.nodeType || !renderNode[node.nodeType]) {
      return <>{children}</>
    }
    const nodeOptions = options[node.nodeType] || {}
    if (nodeOptions.renderer) {
      const renderer = nodeOptions.renderer
      const options = nodeOptions.options || {}
      return renderer(node, children, options)
    }
    return renderNode[(node?.nodeType)](node, children, nodeOptions.options)
  } else {
    return null
  }
}

// TODO:
// - Change import everywhere to `RichText`
// - Use it as component and not as function (function creates problem with isolation for some reason)
export default function documentToReactComponents({
  richTextDocument,
  options = {}
}) {
  const children = nodeToReactComponent(
    richTextDocument,
    {
      renderNode: defaultNodeRenderers,
      renderMark: defaultMarkRenderers,
      renderText: defaultTextRenderers
    },
    options
  )
  return <>{children}</>
}
