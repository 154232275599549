import React from 'react'
import { navigate } from 'gatsby'
import { INLINES, BLOCKS, MARKS } from '@contentful/rich-text-types'
import ContentLink from '../../components/ContentLink'
import EmbedVideo from '../../components/common/EmbedVideo'
import DynamicQuizPlanCTA from '../../components/common/DynamicQuizPlanCTA'
import Button from '../../components/common/Button'
import RichTextComponent from '../../components/LandingPages/components/RichTextComponent'
import Table from '../../components/common/Table'

const inlineAssetRenderer = (node, children, props = {}) => {
  const data =
    node.data &&
    node.data.target.fields &&
    node.data.target.fields.file['en-US']
      ? node.data.target.fields.file['en-US']
      : {}
  return (
    <img
      alt={data.text}
      className={`${props.className || ''}`}
      src={data.url}
    />
  )
}

const blockAssetRenderer = (node, children, props = {}) => {
  const data =
    node.data &&
    node.data.target.fields &&
    node.data.target.fields.file['en-US']
      ? node.data.target.fields.file['en-US']
      : {}
  // Using 85 as the quality setting for now...will play around with this to get a good size / quality ratio
  const quality = 85
  const size480w = `${data.url}?w=480&q=${quality}`
  const size960w = `${data.url}?w=960&q=${quality}`
  const size1920w = `${data.url}?w=1920&q=${quality}`
  return (
    <div className={`${props.className || ''}`}>
      <picture>
        <source
          type="image/webp"
          srcSet={`${size480w}&fm=webp 480w, ${size960w}&fm=webp 960w, ${size1920w}&fm=webp 1920w,`}
          sizes="(max-width: 1920px) 100vw, 1920px"
        />
        <source
          type="image/webp"
          srcSet={`${size480w} 480w, ${size960w} 960w, ${size1920w} 1920w,`}
          sizes="(max-width: 1920px) 100vw, 1920px"
        />
        <img
          sizes="(max-width: 1920px) 100vw, 1920px"
          srcSet={`${size480w} 480w, ${size960w} 960w, ${size1920w} 1920w,`}
          src={size1920w}
          alt={data.text}
          loading="lazy"
        />
      </picture>
    </div>
  )
}

const renderLinkButton = (title, url, props) => {
  return (
    <div className={`${props.className || ''} mt-4 mb-12 w-64`}>
      <Button
        isBlock
        variant="primary"
        className="justify-center"
        onClick={() => navigate(url)}
      >
        {title}
      </Button>
    </div>
  )
}
export const renderTable = data => {
  if (data?.tableData[0]) {
    const dataRows = data?.tableData
    const headers = dataRows[0]
    const values = dataRows.slice(1)
    const rows = values.map(row => {
      return row.reduce((acc, row, idx) => {
        acc[headers[idx]] = row
        return acc
      }, {})
    })
    return <Table rows={rows} />
  }
  return null
}

const richTextEmbeddedEntryRenderer = (node, props = {}) => {
  if (!node.data && !node.data.target && !node.data.target.fields) {
    console.log(
      `richTextEmbeddedEntryRenderer fields missing ${JSON.stringify(
        node,
        null,
        2
      )}`
    )
    return null
  }

  if (!node.data.target.sys.contentType) {
    console.log(
      `richTextEmbeddedEntryRenderer contentType is missing ${JSON.stringify(
        node,
        null,
        2
      )}`
    )
    return null
  }

  const contentType = node.data.target.sys.contentType.sys.id
  // console.log(`richTextEmbeddedEntryRenderer contentType=${contentType}`)

  // Link content type
  if (contentType === 'link') {
    const fields = node.data.target.fields
    const url = fields.url ? fields.url['en-US'] : ''
    const title = fields.title ? fields.title['en-US'] : ''
    const condition = fields.condition ? fields.condition['en-US'] : ''

    // This is a quick way to give embedded video support in articles
    if (url.startsWith('video://')) {
      const videoId = url.substring(8)
      return <EmbedVideo videoId={videoId} autoplay={false} />
    }

    // If the link has a condition set, render the dynamic button
    if (condition && condition !== 'home') {
      return (
        <div
          className={`${props.className ||
            ''} mt-4 mb-8 w-full flex justify-center`}
        >
          <DynamicQuizPlanCTA
            variant="primary"
            text={title}
            slug={url}
            condition={condition}
          />
        </div>
      )
    }

    // Render the standard CTA button with Link content
    return renderLinkButton(title, url, props)
  }

  // Evergreen content type
  // Note: The name is different because it can't be changed in Contentful
  if (contentType === 'medicalTeamPage') {
    const {
      table: { 'en-US': tableData } = {},
      content: { 'en-US': contentJson } = {},
      name: { 'en-US': name } = {},
      configuration: { 'en-US': configuration } = {},
      references: { 'en-US': references } = {},
      images: { 'en-US': images } = {}
    } = node.data.target.fields

    /**
     * If table property is available skip the default
     * evergreen content rendering and render a table instead
     */
    if (tableData) {
      return renderTable(tableData)
    }

    const rtProps = {
      content: {
        json: contentJson
      },
      name,
      configuration: {
        internal: {
          content: JSON.stringify(configuration)
        }
      },
      references,
      images
    }

    return <RichTextComponent content={rtProps} />
  }
}

const richTextHyperlinkRenderer = (node, children, props) => {
  const fields = node.data.target.fields

  if (fields.url && fields.isInternal) {
    const {
      url: { 'en-US': url },
      isInternal: { 'en-US': isInternal }
    } = node.data.target.fields
    const { value: title } = node.content[0]
    const link = { title, url, isInternal }
    return <ContentLink link={link} {...props} />
  }
}

/*
  To support the functionality of custom properties (classes etc)
  we would end up override all the renderers and end up with the below.
  This is why we deleted `rich-text-react-renderer` library, as the only functionality that remains
  is basically a reduce function which is now in `richTextUtils`.
  The main functionality of their library is in rich-text-types library which we have in our app.
  If they add anything else in there we just need to update our default renderers.
  This should not happen too often though as they support almost every html element right now.
*/

// DON'T TOUCH THESE. THESE ARE THE DEFAULTS
export const defaultNodeRenderers = {
  [BLOCKS.DOCUMENT]: (node, children) => children,

  [BLOCKS.PARAGRAPH]: (node, children, props = {}) => (
    <p {...props} className={`${props.className || ''}`}>
      {children}
    </p>
  ),
  [BLOCKS.HEADING_1]: (node, children, props = {}) => (
    <h1 {...props} className={`${props.className || ''}`}>
      {children}
    </h1>
  ),
  [BLOCKS.HEADING_2]: (node, children, props = {}) => (
    <h2 {...props} className={`${props.className || ''}`}>
      {children}
    </h2>
  ),
  [BLOCKS.HEADING_3]: (node, children, props = {}) => (
    <h3 {...props} className={`${props.className || ''}`}>
      {children}
    </h3>
  ),
  [BLOCKS.HEADING_4]: (node, children, props = {}) => (
    <h4 {...props} className={`${props.className || ''}`}>
      {children}
    </h4>
  ),
  [BLOCKS.HEADING_5]: (node, children, props = {}) => (
    <h5 {...props} className={`${props.className || ''}`}>
      {children}
    </h5>
  ),
  [BLOCKS.HEADING_6]: (node, children, props = {}) => (
    <h6 {...props} className={`${props.className || ''}`}>
      {children}
    </h6>
  ),
  [BLOCKS.EMBEDDED_ENTRY]: richTextEmbeddedEntryRenderer,
  // [BLOCKS.EMBEDDED_ENTRY]: (node, children, props={}) => <div {...props}>{children}</div>,
  [BLOCKS.EMBEDDED_ASSET]: blockAssetRenderer,

  [BLOCKS.UL_LIST]: (node, children, props = {}) => (
    <ul {...props} className={`${props.className || ''}`}>
      {children}
    </ul>
  ),
  [BLOCKS.OL_LIST]: (node, children, props = {}) => (
    <ol {...props} className={`${props.className || ''}`}>
      {children}
    </ol>
  ),
  [BLOCKS.LIST_ITEM]: (node, children, props = {}) => (
    <li {...props} className={`${props.className || ''}`}>
      {children}
    </li>
  ),
  [BLOCKS.QUOTE]: (node, children, props = {}) => (
    <blockquote {...props} className={`${props.className || ''}`}>
      {children}
    </blockquote>
  ),
  [BLOCKS.HR]: (node, children, props = {}) => (
    <hr {...props} className={`${props.className || ''}`} />
  ),
  [INLINES.ASSET_HYPERLINK]: inlineAssetRenderer,
  // [INLINES.ASSET_HYPERLINK]: (node, children, props={}) => defaultInline(INLINES.ASSET_HYPERLINK, node, props),
  [INLINES.ENTRY_HYPERLINK]: richTextHyperlinkRenderer,
  // [INLINES.ENTRY_HYPERLINK]: (node,children, props={}) => defaultInline(INLINES.ENTRY_HYPERLINK, node, props),

  [INLINES.EMBEDDED_ENTRY]: (node, children, props = {}) =>
    defaultInline(INLINES.EMBEDDED_ENTRY, node, props),
  [INLINES.HYPERLINK]: (node, children, props = {}) => (
    <a {...props} className={`${props.className || ''}`} href={node.data.uri}>
      {children}
    </a>
  )
}

export const defaultMarkRenderers = {
  [MARKS.BOLD]: (text, children, props = {}) => (
    <b className={`${props.className || ''}`} {...props}>
      {text}
    </b>
  ),
  [MARKS.ITALIC]: (text, children, props = {}) => (
    <i className={`${props.className || ''}`} {...props}>
      {text}
    </i>
  ),
  [MARKS.UNDERLINE]: (text, children, props = {}) => (
    <u className={`${props.className || ''}`} {...props}>
      {text}
    </u>
  ),
  [MARKS.CODE]: (text, children, props = {}) => (
    <code className={`${props.className || ''}`} {...props}>
      {text}
    </code>
  )
}

export const defaultTextRenderers = {
  text: text => {
    if (!text) {
      return null
    }

    return text.split('\n').reduce((children, textSegment, index) => {
      return [...children, textSegment]
    }, [])
  }
}
function defaultInline(type, node, props) {
  return (
    <span
      {...props}
      className={`${props.className || ''}`}
      key={node.data.target.sys.id}
    >
      type: {node.nodeType} id: {node.data.target.sys.id}
    </span>
  )
}
