import React, { useEffect, useState } from 'react'
import Button from '../Button'

export const ConfirmStates = {
  INITIAL_STATE: 'initial',
  TO_CONFIRM: 'to_confirm',
  CONFIRMED: 'confirmed'
}
const InlineConfirmButton = ({
  onConfirm,
  onCancel = () => {},
  onChangeState = () => {},
  children,
  disabled,
  CancelContext,
  ConfirmContext,
  successVariant,
  cancelVariant,
  variant = 'primary',
  size = null,
  state
}) => {
  const [internalState, setInternalState] = useState(
    ConfirmStates.INITIAL_STATE
  )

  useEffect(() => {
    if (state) {
      setInternalState(state)
    }
  }, [state])

  const onConfirmation = newState => {
    let state = null
    if (newState === ConfirmStates.CONFIRMED) {
      onConfirm()
      state = ConfirmStates.CONFIRMED
    }
    if (newState === ConfirmStates.INITIAL_STATE) {
      onCancel()
      state = ConfirmStates.INITIAL_STATE
    }
    if (newState === ConfirmStates.TO_CONFIRM) {
      state = ConfirmStates.TO_CONFIRM
    }
    setInternalState(state)
    onChangeState(state)
  }

  return (
    <>
      {internalState === ConfirmStates.TO_CONFIRM ? (
        <div className={`flex flex-col w-full`}>
          <div className="ml-2">
            <Button
              size="small"
              variant={successVariant || 'success'}
              onClick={e => {
                e.stopPropagation()
                onConfirmation(ConfirmStates.CONFIRMED)
              }}
              disabled={disabled}
            >
              {ConfirmContext ? <ConfirmContext /> : `Add`}
            </Button>
          </div>
          <div className="ml-2">
            <Button
              size="small"
              variant={cancelVariant || 'secondary'}
              onClick={e => {
                e.stopPropagation()
                onConfirmation(ConfirmStates.INITIAL_STATE)
              }}
              disabled={disabled}
            >
              {CancelContext ? <CancelContext /> : 'Cancel'}
            </Button>
          </div>
        </div>
      ) : (
        <Button
          variant={variant}
          size={size}
          onClick={e => {
            e.stopPropagation()
            onConfirmation(ConfirmStates.TO_CONFIRM)
          }}
          disabled={disabled}
        >
          {children}
        </Button>
      )}
    </>
  )
}

export default InlineConfirmButton
