import React from 'react'
import styled from 'styled-components'
import documentToReactComponents from '../../../utils/richTextUtils'
import ContentLink from '../../ContentLink'
import Img from 'gatsby-image'
import { ParseConfiguration } from '../../../utils/helpers'

const RichTextComponent = ({ content }) => {
  const { configuration, references, images } =
    content || {}
  const json = (content.content || {}).json
  const {
    className,
    imgClassName,
    innerClass,
    style,
    imgStyle,
    innerStyle
  } = ParseConfiguration(configuration)
  const link = (references || []).find(r => r.__typename === 'ContentfulLink')
  const image = (images || []).length > 0 ? images[0] : null

  const ContainerDiv = styled.div`
    ${style}
  `
  const ImgDiv = styled.div`
    ${imgStyle}
  `
  const InnerDiv = styled.div`
    ${innerStyle}
  `
  return (
    <>
      <ContainerDiv
        data-contentfulid={content.contentful_id}
        className={`${
          className
            ? className
            : 'flex flex-col justify-center md:w-1/2 w-full p-10'
        } markdown landing`}
      >
        {image && (
          <ImgDiv className={imgClassName}>
            <Img fluid={image.fluid} alt={image.title} />
          </ImgDiv>
        )}
        {json && (
          <InnerDiv className={innerClass}>
            {documentToReactComponents({
              richTextDocument: json
            })}
          </InnerDiv>
        )}
        {link && (
          <div className="mr-auto">
            <ContentLink
              link={link}
              className="block md:mx-0 md:inline-block cursor-pointer btn btn--primary hover:bg-primary-hover bold text-white hover:text-white mt-6 px-6 py-3 leading-tight rounded focus:outline-none my-2 no-underline"
            />
          </div>
        )}
      </ContainerDiv>
    </>
  )
}

export default RichTextComponent
