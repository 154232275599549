import React, { useEffect, useState, isValidElement } from 'react'
import Button from './Button'
import { uuid } from '../../../common/utils/id_generator'
import InlineConfirmButton, {
  ConfirmStates
} from './InlineConfirm/InlineConfirmButton'
function Table({
  rows = [],
  onSelectRow = null,
  allowedPropertiesInRows = null,
  prettyHeaderTitles = {},
  onDelete = null,
  onEdit = null,
  rowRenderer = null,
  headerRenderer = null,
  withConfirmation = false,
  theme = 'light',
  variant = 'striped',
  getRowClassName = () => {}
}) {
  const [deletedItem, setDeletedItem] = useState(null)
  const [internalRows, setInternalRows] = useState([])
  useEffect(() => {
    setInternalRows(
      rows.map((row) => {
        row._id = uuid()
        return row
      })
    )
  }, [rows])
  const deleteEnabled = onDelete !== null
  const editEnabled = onEdit !== null
  const getRowClassNameInternal = (i) => {
    let className =
      variant === 'striped' && i % 2 !== 0
        ? theme === 'light'
          ? 'bg-white'
          : 'bg-black-light'
        : ''
    if (onSelectRow) {
      className = `${className} cursor-pointer`
    }
    if (getRowClassName) {
      return `${className} ${getRowClassName(i)}`
    }
    return className
  }

  //TODO: Decide colors after designer iteration
  const tableClassName =
    theme === 'light' ? 'bg-gp-grey-5 text-gp-grey-90' : 'bg-black text-white'
  const headerClassName =
    theme === 'light'
      ? 'bg-gp-grey-5 text-gp-grey-90'
      : 'bg-primary-dark text-white'

  const rowsToRender =
    allowedPropertiesInRows !== null
      ? internalRows.map((row) => {
          return allowedPropertiesInRows.reduce((acc, property) => {
            if (!acc._id) {
              acc._id = row._id
            }
            if (row[property] !== undefined) {
              acc[property] = row[property]
            } else {
              acc[property] = ''
            }
            return acc
          }, {})
        })
      : internalRows
  const { _id, ...header } = { ...rowsToRender[0] }
  const rowHeader = Object.keys(header || {}).map((title) =>
    prettyHeaderTitles ? prettyHeaderTitles[title] ?? title : title
  )
  if (onDelete || onEdit) {
    rowHeader.push('Actions')
  }

  return (
    <div className={`w-full ${tableClassName}`}>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gp-violet-30 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gp-violet-30">
                <thead>
                  <tr className="divide-x divide-gp-violet-30">
                    {rowHeader.map((title, i) => {
                      if (
                        headerRenderer &&
                        typeof headerRenderer === 'function'
                      ) {
                        return (
                          <th
                            key={title}
                            className={`px-4 py-3 bg-gp-grey-5 text-gp-grey-80  text-left text-sm leading-tight font-bold uppercase tracking-wider ${headerClassName}`}
                          >
                            {headerRenderer(title, i)}
                          </th>
                        )
                      }
                      return (
                        <th
                          key={title}
                          className={`px-4 py-3 bg-gp-grey-5 text-gp-grey-80  text-left text-sm leading-tight font-bold uppercase tracking-wider ${headerClassName}`}
                        >
                          {title}
                        </th>
                      )
                    })}
                  </tr>
                </thead>
                <tbody>
                  {rowsToRender.map((item, i) => {
                    return (
                      <tr
                        onClick={() => {
                          if (onSelectRow) {
                            const selectedRow = internalRows.find(
                              (row) => item._id === row._id
                            )
                            onSelectRow(selectedRow)
                          }
                        }}
                        key={item._id}
                        className={`${getRowClassNameInternal(i)}`}
                      >
                        {Object.entries(item).reduce((acc, [key, col], i) => {
                          if (key === '_id') {
                            return acc
                          }
                          if (
                            rowRenderer &&
                            typeof rowRenderer === 'function'
                          ) {
                            acc.push(
                              <td
                                key={i}
                                className="px-4 py-4 whitespace-no-wrap text-base leading-snug font-bold text-gp-grey-90"
                              >
                                {rowRenderer([key, col, item], i)}
                              </td>
                            )
                            return acc
                          }
                          if (isValidElement(col)) {
                            acc.push(
                              <td
                                key={i}
                                className="px-4 py-4 whitespace-no-wrap text-base leading-snug font-bold text-gp-grey-90"
                              >
                                <div key={key}>{col}</div>
                              </td>
                            )
                            return acc
                          }
                          acc.push(
                            <td
                              key={i}
                              className="px-4 py-4 whitespace-no-wrap text-base leading-snug font-bold text-gp-grey-90"
                            >
                              <div key={key}>{col?.toString()}</div>
                            </td>
                          )
                          return acc
                        }, [])}
                        {deleteEnabled &&
                          deletedItem &&
                          deletedItem?._id === item._id && (
                            <td className="px-4 py-4 whitespace-no-wrap text-base leading-snug font-bold text-gp-grey-90">
                              <InlineConfirmButton
                                ConfirmContext={() => `Confirm`}
                                CancelContext={() => `Cancel`}
                                successVariant="danger"
                                onCancel={() => {
                                  setDeletedItem(null)
                                }}
                                onConfirm={() => {
                                  setDeletedItem(null)
                                  const row = internalRows.find(
                                    (row) => deletedItem._id === row._id
                                  )
                                  onDelete(row)
                                }}
                                state={
                                  deletedItem
                                    ? ConfirmStates.TO_CONFIRM
                                    : ConfirmStates.INITIAL_STATE
                                }
                              />
                            </td>
                          )}
                        <td className="px-4 py-4 whitespace-no-wrap text-base leading-snug font-bold text-gp-grey-90">
                          <div className="flex flex-row space-x-4">
                            {editEnabled && deletedItem?._id !== item._id && (
                              <Button
                                size="small"
                                onClick={() => {
                                  const row = internalRows.find(
                                    (row) => item._id === row._id
                                  )
                                  onEdit(row)
                                }}
                              >
                                Edit
                              </Button>
                            )}
                            {deleteEnabled && deletedItem?._id !== item._id && (
                              <Button
                                variant="danger"
                                size="small"
                                onClick={(e) => {
                                  const row = internalRows.find(
                                    (row) => item._id === row._id
                                  )
                                  if (withConfirmation) {
                                    return setDeletedItem(row)
                                  }
                                  onDelete(row)
                                }}
                              >
                                Delete
                              </Button>
                            )}
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Table
