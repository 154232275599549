import React from 'react'
import Button from './Button'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import { CONDITION_SLUG, PLAN_ID, currentActiveQuizKey } from '../../constants/localStorage'
import { useUserContext } from '../../contexts/User/userContext'
import useLocalStorage from '../../utils/useLocalStorage'
import { conditionSlugs } from 'goodpath-common'

export const getQuizNavigationState = (
  planId,
  planCondition,
  currentQuizConditionSlug,
  pageCondition,
  getStartedOverrideText = null,
  slug,
  isAuth,
  enrolled
) => {
  let navigationUrl = '/select-condition'
  const navigationText = getStartedOverrideText
    ? getStartedOverrideText
    : 'Get Started'

  if (isAuth && enrolled) {
    // Logged in user or newly subscribed, navigate to member dashboard
    return {
      navigationUrl: `/mycare`,
      navigationText: 'View My Care'
    }
  } else if (
    isAuth &&
    ((planId &&
      planCondition &&
      (planCondition === pageCondition || pageCondition === 'home')) ||
      (currentQuizConditionSlug &&
        (currentQuizConditionSlug === pageCondition ||
          pageCondition === 'home')))
  ) {
    return {
      navigationUrl: `/quiz/${currentQuizConditionSlug}?resume=true`,
      navigationText: 'Resume'
    }
  }

  // Allow overriding of the starting slug for condition pages
  if (pageCondition !== 'home') {
    if (isAuth) {
      navigationUrl = `/select-condition?condition=${pageCondition}`
    } else {
      navigationUrl = slug
        ? slug
        : `/select-condition?condition=${pageCondition}`
    }
  }

  return {
    navigationUrl,
    navigationText
  }
}

export default function DynamicQuizPlanCTA({ variant, condition, slug, text }) {
  const { user } = useUserContext()
  const [planId] = useLocalStorage(PLAN_ID)
  const [planCondition] = useLocalStorage(CONDITION_SLUG)
  const [currentQuizConditionSlug] = useLocalStorage(currentActiveQuizKey)
  const { navigationUrl, navigationText } = getQuizNavigationState(
    planId,
    planCondition,
    currentQuizConditionSlug,
    condition,
    text,
    slug,
    user.isAuth,
    user.enrolled
  )

  return (
    <Button variant={variant} onClick={() => navigate(navigationUrl)}>
      {navigationText}
    </Button>
  )
}

DynamicQuizPlanCTA.propTypes = {
  variant: PropTypes.oneOf(['primary', 'text', 'secondary']),
  condition: PropTypes.oneOf(['home'].concat(conditionSlugs)),
  slug: PropTypes.string,
  text: PropTypes.string
}
